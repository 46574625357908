import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Swiper from 'swiper';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

window.gsap = gsap;

(function ($) {

  $(".btn-wrapper").mousemove(function (e) {
    callParallax(e);
  });

  function callParallax(e) {
    parallaxIt(e, ".btn--play", 80);
  }

  function parallaxIt(e, target, movement) {
    var $this = $(".btn-wrapper");
    var boundingRect = $this[0].getBoundingClientRect();
    var relX = e.pageX - boundingRect.left;
    var relY = e.pageY - boundingRect.top;
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    gsap.to(target, 0.1, {
      x: ((relX - boundingRect.width / 2) / boundingRect.width) * movement,
      y:
        ((relY - boundingRect.height / 2 - scrollTop) / boundingRect.width) *
        movement,
    });
  }

  if ($('body').hasClass('first-load')) {
    if (jQuery("body").hasClass("first-load")) {
      gsap.to(".section-home-hero-content", {
        x: 0,
        duration: 1,
        delay: 2.3,
      });

      gsap.to(".section-home-hero .chair-path", {
        scale: 1,
        duration: 1,
        delay: 2.2,
      });

      gsap.to(".section-home-hero .section-home-hero-chair", {
        rotate: 0,
        duration: 1,
        delay: 2.2,
      });


    } else {
      gsap.to(".section-home-hero-content", {
        x: 0,
        duration: 1,
        delay: 0,
      });

      gsap.to(".section-home-hero .chair-path", {
        scale: 1,
        duration: 1,
        delay: 0,
      });

      gsap.to(".section-home-hero .section-home-hero-chair", {
        rotate: 0,
        duration: 1,
        delay: 0,
      });
    }
  }

  if ($('.section-discover').length > 0) {
    gsap.to(".section-discover .discover-image-1", {
      rotate: 0,
      scrollTrigger: {
        trigger: ".section-discover",
        start: "20% 75%",
        once: true,
      },
      duration: 1,
    });

    gsap.to(".section-discover .discover-image-2", {
      rotate: 0,
      scrollTrigger: {
        trigger: ".section-discover",
        start: "20% 75%",
        once: true,
      },
      duration: 1,
    });

    gsap.to(".section-discover .content", {
      y: 0,
      scrollTrigger: {
        trigger: ".section-discover",
        start: "20% 75%",
        once: true,
      },
      duration: 1,
    });
  }

  if ($('.section-thestory').length > 0) {
    gsap.to(".section-thestory .image1", {
      rotate: 0,
      scrollTrigger: {
        trigger: ".section-thestory .lead",
        start: "20% 75%",
        once: true,
      },
      duration: 0.7,
    });

    gsap.to(".section-thestory .image2", {
      rotate: 0,
      scrollTrigger: {
        trigger: ".section-thestory .lead",
        start: "top top",
        once: true,
      },
      duration: 0.7,
    });

    gsap.to(".section-thestory .image3", {
      rotate: 0,
      scrollTrigger: {
        trigger: ".section-thestory .lead",
        start: "top top",
        once: true,
      },
      duration: 0.7,
    });

    gsap.to(".section-thestory .link--big", {
      scale: 1,
      duration: 0.9,
      scrollTrigger: {
        trigger: ".section-thestory .image3",
        start: "top top",
      },
    });
  }

  if ($('.section-slider').length > 0) {
    let currentIndex = 0;
    const slideItems = jQuery(".about-slider .slide");
    slideItems.hide();
    slideItems.eq(currentIndex).addClass("current");
    slideItems.eq(currentIndex).show();

    function setSlide(index) {
      var tl = gsap.timeline();

      tl.fromTo(
        ".about-slider .slider-cover",
        0.5,
        {
          transformOrigin: "top left",
          scaleX: 0,
        },
        {
          transform0rigin: "top right",
          scaleX: 1,
        }
      )
        .fromTo(
          ".about-slider .slide.current",
          0,
          {
            opacity: 0,
          },
          {
            opacity: 1,
          }
        )
        .add(function () {
          slideItems.removeClass("current");
          slideItems.hide();
          slideItems.eq(index).addClass("current");
          slideItems.eq(index).show();
        })
        .to(".about-slider .slider-cover", 0.5, {
          transformOrigin: "top right",
          scaleX: 0,
        })
        .to(
          ".about-slider .slide.current",
          0.25,
          {
            backgroundPosition: "0 0",
          },
          "-=.75"
        );

      tl.play();
    }

    setSlide(0);

    jQuery(".next-slide-button").click(function () {
      if (currentIndex < slideItems.length - 1) {
        currentIndex++;
        setSlide(currentIndex);
      } else {
        currentIndex = 0;
        setSlide(currentIndex);
      }
    });
  }

  if ($('.section-aboutus').length > 0) {
    if (Foundation.MediaQuery.is("large up")) {
      var timelineImageOne = gsap
        .timeline({
          paused: true,
        })
        .to(".section-aboutus .image_1", 2, {
          x: -100,
          y: -100,
          rotate: 0,
        });

      var timelineImageTwo = gsap
        .timeline({
          paused: true,
        })
        .to(".section-aboutus .image_3", 2, {
          x: 100,
          y: -100,
          rotate: 0,
        });

      jQuery(document).scroll(function () {
        if (
          jQuery(".image-animation").position().top <=
          jQuery(document).scrollTop() &&
          jQuery(".image-animation").position().top +
          jQuery(".image-animation").outerHeight() >
          jQuery(document).scrollTop()
        ) {
          let height =
            jQuery(".image-animation").position().top +
            jQuery(".image-animation").outerHeight() -
            (jQuery(".image-animation").position().top +
              jQuery(".image-animation").outerHeight() -
              jQuery(".image-animation").height());
          let scrollTop = jQuery(document).scrollTop();
          let percent = scrollTop / height;
          timelineImageOne.progress(percent.toFixed(2) - 0.15).pause();
          timelineImageTwo.progress(percent.toFixed(2) - 0.15).pause();
        }
      });
    }

    gsap.to(".section-aboutus h1", {
      scale: 1,
      duration: 1.3,
    });
  }

  if ($('.section-home-hero').length > 0 && Foundation.MediaQuery.is('large down')) {
    new Swiper('.slider-hero', {
      loop: true,
      slidesPerView: 'auto',
      spaceBetween: 16,
    });
  }

})(jQuery);